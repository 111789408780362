import React, { useState, useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import styled from 'styled-components';
import { Col } from 'styled-bootstrap-grid';
import Helmet from 'react-helmet';
// import { useFlexSearch } from 'react-use-flexsearch';
import { useLunr } from 'react-lunr';

const ArtistsContainer = styled.ul`
  width: 100%;

  list-style: none;
  margin: 75px 0 0 0;
  padding: 0;

  column-count: 3;

  font-size: 28px;
  line-height: 40px;

  & li {
    cursor: pointer;

    &:hover {
      color: red;
    }
  }

  @media (max-width: 900px) {
    column-count: 2;
  }

  @media (max-width: 767px) {
    column-count: 1;
  }

  @media (max-width: 767px) {
    font-size: 27px;

    margin: 0 auto 20px auto;
  }

  @media (max-width: 420px) {
    font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300)));
    line-height: 1.5;
  }
`;

const Associates = ({ data }) => {
  const index = data.localSearchProducts.index;
  const store = data.localSearchProducts.store;

  const [query, setQuery] = useState('');
  const results = useLunr(query, index, JSON.parse(store));

  const searchForArtist = artistName => {
    setQuery(artistName);
  };

  useEffect(() => {
    if (query !== '') {
      navigate('/search-results/', {
        state: {
          searchResults: results,
          searchQuery: query,
        },
      });
    }
  }, [query]);

  const artistList = data.prismicArtists.data.artists
    .sort()
    .map((artist, index) => {
      const artistName = artist.artist;
      return (
        <li
          key={`artists_list_${index}`}
          onClick={() => searchForArtist(artistName)}
        >
          {artistName}
        </li>
      );
    });

  return (
    <>
      <Helmet title={`Associates - Askeaton Contemporary Arts`} />
      <Col col={12} lg={11} xl={10}>
        <ArtistsContainer>{artistList}</ArtistsContainer>
      </Col>
    </>
  );
};

// getOrCreateIndex = () =>
//   this.index
//     ? this.index
//     : // Create an elastic lunr index and hydrate with graphql query results
//       Index.load(this.props.data.siteSearchIndex.index);

// search = artistName => {
//   const query = artistName;

//   this.index = this.getOrCreateIndex();

//   this.setState(
//     {
//       query,
//       // Query the index with search string to get an [] of IDs
//       results: this.index
//         .search(query, { expand: false })
//         // Map over each ID and return the full document
//         .map(({ ref }) => this.index.documentStore.getDoc(ref)),
//     },
//     () => {
//       const searchValues = this.state.results;
//       const searchQuery = this.state.query;

//       if (searchQuery.length >= 1) {
//         navigate('/search-results/', {
//           state: {
//             searchValues,
//             searchQuery,
//           },
//         });
//       }
//     }
//   );
// };
// }
// siteSearchIndex {
//   index
// }

export default Associates;

export const pageQuery = graphql`
  query Artists {
    localSearchProducts {
      index
      store
    }

    prismicArtists {
      data {
        artists {
          artist
        }
      }
    }
  }
`;
